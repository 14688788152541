import React, { FunctionComponent, useContext } from 'react';
import { getDividerTitle } from '../Section.helper';
import { ListModel } from '../../../services/content/list/models/ListModel';
import { SectionsDivider } from '../SectionsDivider';
import { SectionLeadingContent } from '../SectionLeadingContent';
import { SectionContext } from '../../../contexts/SectionContext';
import { Routes } from '../../../constants/Routes';
import { GoogleAdDesktop } from '../../Ads/GoogleAdDesktop';
import { ArticleModel } from '../../../services/content/article/models/ArticleModel';
import { VideoModel } from '../../../services/content/video/models/VideoModel';
import { AdBackground } from '../../Ads/AdBackground';
import { GoogleAdMobile } from '../../Ads/GoogleAdMobile';
import { AdModel } from '../../../services/ads/models/AdModel';
import styles from '../../../styles/NewsSection.module.scss';
import { SectionSecondaryContentVertical } from '../SectionSecondaryContentVertical';

type Props = {
    section: ListModel | null | undefined;
    ads: AdModel[];
    lazyLoadImage?: boolean;
};

export const FourArticlesHorizontalBannerSectionTemplate: FunctionComponent<Props> = ({
    section,
    ads,
    lazyLoadImage,
}) => {
    const sectionContext = useContext(SectionContext);
    const pageIndex = sectionContext.pageIndex;
    const displayDivider = pageIndex > 0;

    if (section && section.items) {
        const leadingContent = section.items[0];
        const secondaryContent = section.items.filter((item, idx) => idx > 0);
        const dividerTitle = getDividerTitle(section);

        return (
            <div className={`container mb-0 mb-md-5 ${styles.newsContainer}`}>
                <SectionsDivider
                    title={dividerTitle}
                    display={displayDivider}
                    link={Routes.openCategory(section?.configuration?.category?.seo?.slug)}
                />
                <div className={`d-flex flex-row py-3 justify-content-center py-md-0 mb-0 mb-md-5 w-100`}>
                    <SectionLeadingContent
                        content={leadingContent}
                        lazyLoadImage={lazyLoadImage ? lazyLoadImage : false}
                        category={
                            section.configuration?.category && section.configuration?.category.id
                                ? section.configuration?.category
                                : leadingContent?.category
                        }
                    />
                </div>
                <div className={`row px-0 mx-0 justify-content-center`}>
                    {secondaryContent.map((content, idx) => {
                        content = content as ArticleModel | VideoModel;
                        return (
                            <SectionSecondaryContentVertical
                                key={`secondary-four-articles-key-${content.id}`}
                                className='col-12 col-md-4'
                                content={content}
                                lazyLoadImage={lazyLoadImage ? lazyLoadImage : false}
                                category={
                                    section.configuration?.category && section.configuration?.category.id
                                        ? section.configuration?.category
                                        : content?.category
                                }
                            />
                        );
                    })}
                </div>
                <div className={'d-none d-md-inline-block d-lg-inline-block text-center w-100 mt-2 mb-2'}>
                    <GoogleAdDesktop ad={ads[0]} />
                </div>
                <div className={'d-inline-block d-md-none d-lg-none text-center w-100 mt-2 mb-2'}>
                    <AdBackground>
                        <GoogleAdMobile ad={ads[0]} />
                    </AdBackground>
                </div>
            </div>
        );
    }

    return null;
};
