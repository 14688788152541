import React, { FunctionComponent, PropsWithChildren, useContext } from 'react';
import { getDividerTitle } from '../Section.helper';
import { ListModel } from '../../../services/content/list/models/ListModel';
import { SectionsDivider } from '../SectionsDivider';
import { SectionLeadingContent } from '../SectionLeadingContent';
import { SectionSecondaryContent } from '../SectionSecondaryContent';
import { SectionContext } from '../../../contexts/SectionContext';
import { Routes } from '../../../constants/Routes';
import { GoogleAdDesktop } from '../../Ads/GoogleAdDesktop';
import { ArticleModel } from '../../../services/content/article/models/ArticleModel';
import { VideoModel } from '../../../services/content/video/models/VideoModel';
import { AdBackground } from '../../Ads/AdBackground';
import { GoogleAdMobile } from '../../Ads/GoogleAdMobile';
import { AdModel } from '../../../services/ads/models/AdModel';
import styles from '../../../styles/NewsSection.module.scss';

type SectionProps = {
    section: ListModel | null | undefined;
    ads: AdModel[];
    lazyLoadImage?: boolean;
};

export const FourArticlesHorizontalAndRectBannerSectionTemplate: FunctionComponent<SectionProps> = ({
    section,
    ads,
    lazyLoadImage,
}) => {
    const sectionContext = useContext(SectionContext);
    const pageIndex = sectionContext.pageIndex;
    const displayDivider = pageIndex > 0;

    //Initialize ads as an empty array if undefined or null
    ads = ads ?? [];

    if (section && section.items) {
        const leadingContent = section.items[0];
        const secondaryContent = section.items.filter((item, idx) => idx > 0);
        const dividerTitle = getDividerTitle(section);

        return (
            <div className={`container mb-0 mb-md-5 ${styles.newsContainer}`}>
                <SectionsDivider
                    title={dividerTitle}
                    display={displayDivider}
                    link={Routes.openCategory(section?.configuration?.category?.seo?.slug)}
                />
                <div className={`d-flex flex-row py-3 justify-content-center py-md-0 mb-0 mb-md-5 w-100`}>
                    <SectionLeadingContent
                        content={leadingContent}
                        lazyLoadImage={lazyLoadImage ? lazyLoadImage : false}
                        category={
                            section.configuration?.category && section.configuration?.category.id
                                ? section.configuration?.category
                                : leadingContent?.category
                        }
                    />
                </div>
                <div className={`row px-0 mx-0 justify-content-center`}>
                    {secondaryContent.map((content, idx) => {
                        content = content as ArticleModel | VideoModel;
                        return (
                            <SecondaryNewsBannerWrapper
                                key={`secondary-four-articles-two-banners-key-${content.id}`}
                                idx={idx}
                                ads={ads}>
                                <SectionSecondaryContent
                                    className='col-12 col-md-3 mb-md-3'
                                    content={content}
                                    lazyLoadImage={lazyLoadImage ? lazyLoadImage : false}
                                    category={
                                        section.configuration?.category && section.configuration?.category.id
                                            ? section.configuration?.category
                                            : content?.category
                                    }
                                />
                            </SecondaryNewsBannerWrapper>
                        );
                    })}
                </div>
                <div className='w-100 text-center'>
                    <div className='d-none d-md-inline-block d-lg-inline-block text-center'>
                        <AdBackground>
                            <GoogleAdDesktop ad={ads[1]} />
                        </AdBackground>
                    </div>
                </div>
                <div className='w-100 text-center'>
                    <div className='d-inline-block d-md-none d-lg-none text-center ad-container-h600'>
                        <AdBackground className='text-center sticky-ad'>
                            <GoogleAdMobile ad={ads[1]} />
                        </AdBackground>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

const SecondaryNewsBannerWrapper: FunctionComponent<PropsWithChildren<{ idx: number; ads: AdModel[] }>> = ({
    children,
    idx,
    ads,
}) => {
    const SECOND_ARTICLE = 0;
    const FOURTH_ARTICLE = 2;

    if (idx === SECOND_ARTICLE) {
        return (
            <>
                {children}
                <AdBackground className='d-inline-block d-md-none d-lg-none text-center'>
                    <GoogleAdMobile ad={ads[0]} />
                </AdBackground>
            </>
        );
    }

    if (idx === FOURTH_ARTICLE) {
        return (
            <>
                {children}
                <div className='d-none d-md-inline-block d-lg-inline-block text-center col-md-3 mb-md-3'>
                    <AdBackground>
                        <GoogleAdDesktop ad={ads[0]} />
                    </AdBackground>
                </div>
            </>
        );
    }

    return <>{children}</>;
};
