import { CategoryModel } from './models/CategoryModel';
import { fetchAllCategories, fetchCategoriesById, fetchCategoryBySlug } from './http/CategoryHttp';
import { normalizeCategories, normalizeCategory } from './normalizers/CategoryNormalizer';
import fetch from 'isomorphic-fetch';

export const getCategoriesByIds = async (categoryIds: string[]): Promise<CategoryModel[]> => {
    const response = await fetchCategoriesById(categoryIds);
    return normalizeCategories(response);
};

export const getAllActiveCategories = async (): Promise<CategoryModel[]> => {
    try {
        const response = await fetchAllCategories();
        return normalizeCategories(response).filter((category) => category.active);
    } catch (e) {
        console.error('Error retrieving full list of categories: ', e);
        return [];
    }
};

export const getCategoryBySlug = async (slug: string | null | undefined): Promise<CategoryModel | null> => {
    if (slug) {
        try {
            const response = await fetchCategoryBySlug(slug);
            if (response && Array.isArray(response)) {
                return normalizeCategory(response[0]);
            }
            return null;
        } catch (e) {
            console.error('Error retrieving category by slug: ', e);
            return null;
        }
    }

    return null;
};

export const getCategoryBySlugFromProxy = async (slug: string | null | undefined): Promise<any> => {
    if (slug) {
        const response: any = await fetch(`${process.env.NEXT_PUBLIC_WEBSITE_URL}/api/categories?slug=${slug}`, {
            method: 'GET',
        });
        const responseParsed = await response.json();
        return await responseParsed.content;
    }

    return null;
};
