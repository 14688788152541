import { FunctionComponent } from 'react';
import { Pages } from '../../constants/Enums';
import { ArticleModel } from '../../services/content/article/models/ArticleModel';
import { CategoryModel } from '../../services/content/category/models/CategoryModel';
import { TagModel } from '../../services/content/tag/models/TagModel';
import { AuthorModel } from '../../services/content/author/models/AuthorModel';
import { getMainMediaImageOriginalUrl } from '../../services/content/mainMedia/MainMedia';
import { hasAuthors, hasCustomAuthors } from '../../constants/GlobalHelpers';

type Props = {
    page: Pages;
    article?: ArticleModel;
    category?: CategoryModel | null;
    tag?: TagModel | null;
    author?: AuthorModel | null;
};

const LdJsonMain = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    url: 'https://woman.bg/',
    logo: 'https://woman.bg/static/images_new/logo.png',
    email: 'mailto:office@sportal.bg',
    description: 'Woman.bg - мода, красота, диети и фитнес, секс и връзки, мама и дете, рецепти, пътешествия, за дома',
    name: 'Woman.bg',
    telephone: '+359 2 971 03 11',
    sameAs: ['https://www.facebook.com/woman.bg'],
    potentialAction: {
        '@type': 'SearchAction',
        target: 'https://woman.bg/search?q=search_term_string',
    },
};

export const LdJson: FunctionComponent<Props> = ({ page, article, category, tag, author }) => {
    const pageSpecificLdJson = generateLdJsonForSpecificPage(page, article, category, tag, author);
    return (
        <>
            <script
                type='application/ld+json'
                dangerouslySetInnerHTML={{ __html: JSON.stringify(LdJsonMain) }}></script>
            <script
                type='application/ld+json'
                dangerouslySetInnerHTML={{ __html: JSON.stringify(pageSpecificLdJson) }}></script>
            {page === Pages.ARTICLE ? (
                <script
                    type='application/ld+json'
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify(generateArticleDetailsLdJson(article)),
                    }}></script>
            ) : null}
        </>
    );
};

export const generateArticleDetailsLdJson = (article?: ArticleModel | null) => {
    return {
        '@context': 'https://schema.org',
        '@type': 'NewsArticle',
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': `${process.env.NEXT_PUBLIC_WEBSITE_URL}/article/${article?.id}`,
        },
        headline: `${article?.title}`,
        image: [`${article?.mainMedia ? getMainMediaImageOriginalUrl(article?.mainMedia) : ''}`],
        datePublished: `${article?.publishedAt}`,
        dateModified: `${article?.updatedAt}`,
        author: {
            '@type': 'Person',
            name: `${retrieveAuthorNameFromArticle(article)}`,
        },
        publisher: {
            '@type': 'Organization',
            name: 'Woman.bg',
            logo: {
                '@type': 'ImageObject',
                url: `${process.env.NEXT_PUBLIC_WEBSITE_URL}/woman_logo.svg`,
            },
        },
        description: `${article?.subtitle}`,
    };
};

export const generateLdJsonForSpecificPage = (
    page: Pages,
    article?: ArticleModel | null,
    category?: CategoryModel | null,
    tag?: TagModel | null,
    author?: AuthorModel | null,
) => {
    const ldJson = {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [] as any[],
    };

    const PAGE_LD_JSON_ITEMS_MAPPING = {
        [`${Pages.ARTICLE}`]: [
            {
                '@type': 'ListItem',
                position: '1',
                item: {
                    '@id': 'https://woman.bg/',
                    name: 'Начало',
                },
            },
            {
                '@type': 'ListItem',
                position: '2',
                item: {
                    '@id': `https://woman.bg/${article?.category?.seo.slug}`,
                    name: article?.category?.title,
                },
            },
            {
                '@type': 'ListItem',
                position: 3,
                item: {
                    '@id': `https://woman.bg/${article?.id}`,
                    name: article?.title,
                },
            },
        ],
        [`${Pages.LANDING}`]: [
            {
                '@type': 'ListItem',
                position: '1',
                item: {
                    '@id': 'https://woman.bg/',
                    name: 'Начало',
                },
            },
        ],
        [`${Pages.SEARCH}`]: [
            {
                '@type': 'ListItem',
                position: '1',
                item: {
                    '@id': 'https://woman.bg/',
                    name: 'Начало',
                },
            },
        ],
        [`${Pages.CATEGORY}`]: [
            {
                '@type': 'ListItem',
                position: '1',
                item: {
                    '@id': 'https://woman.bg/',
                    name: 'Начало',
                },
            },
            {
                '@type': 'ListItem',
                position: '2',
                item: {
                    '@id': `https://woman.bg/${category?.seo.slug}`,
                    name: category?.title,
                },
            },
        ],
        [`${Pages.TAG}`]: [
            {
                '@type': 'ListItem',
                position: '1',
                item: {
                    '@id': 'https://woman.bg/',
                    name: 'Начало',
                },
            },
            {
                '@type': 'ListItem',
                position: '2',
                item: {
                    '@id': `https://woman.bg/tag/${tag?.seo.slug}`,
                    name: tag?.title,
                },
            },
        ],
        [`${Pages.AUTHOR}`]: [
            {
                '@type': 'ListItem',
                position: '1',
                item: {
                    '@id': 'https://woman.bg/',
                    name: 'Начало',
                },
            },
            {
                '@type': 'ListItem',
                position: '2',
                item: {
                    '@id': `https://woman.bg/author/${author?.id}`,
                    name: author?.name,
                },
            },
        ],
        [`${Pages.VIDEOS}`]: [
            {
                '@type': 'ListItem',
                position: '1',
                item: {
                    '@id': 'https://woman.bg/',
                    name: 'Начало',
                },
            },
            {
                '@type': 'ListItem',
                position: 2,
                item: {
                    '@id': 'https://woman.bg/videos/',
                    name: 'Видеа',
                },
            },
        ],
    };

    ldJson.itemListElement = PAGE_LD_JSON_ITEMS_MAPPING[`${page}`];

    return ldJson;
};

const retrieveAuthorNameFromArticle = (article?: ArticleModel | null) => {
    let authorName: string = '';

    if (hasAuthors(article?.authors)) {
        authorName = article?.authors[0] ? article.authors[0].name : '';
    }

    if (authorName.length < 1 && hasCustomAuthors(article?.customAuthor)) {
        authorName = article?.customAuthor ? article.customAuthor : '';
    }

    return authorName;
};
