import React, { FunctionComponent } from 'react';
import { HomepageConfigSectionModel } from '../../../services/configurator/homepage/models/HomepageConfigModel';
import { SectionContext } from '../../../contexts/SectionContext';
import { RenderSection } from '../SectionsRenderer';

type Props = {
    sections: HomepageConfigSectionModel[];
    sectionAdsCounter: any;
    startingSection: number;
};

export const LazyLoadSections: FunctionComponent<Props> = ({ sections, sectionAdsCounter, startingSection }) => {
    if (sections && sections.length > 0) {
        return (
            <>
                {sections.map((section, idx) => {
                    return (
                        <SectionContext.Provider key={section.listId} value={{ pageIndex: idx + startingSection }}>
                            <RenderSection section={section} sectionAdsCounter={sectionAdsCounter} />
                        </SectionContext.Provider>
                    );
                })}
            </>
        );
    }

    return <></>;
};
