import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import Head from 'next/head';
import Layout from '../components/Layout/Layout';
import { NavigationMenuContext } from '../contexts/NavigationMenuContext';
import {
    HomepageConfigModel,
    HomepageConfigSectionModel,
} from '../services/configurator/homepage/models/HomepageConfigModel';
import { getHomepageContent } from '../services/configurator/homepage/HomepageConfigurator';
import { CategoryModel } from '../services/content/category/models/CategoryModel';
import { getNavbarCategories } from '../services/configurator/navbar/NavbarConfigurator';
import { SectionContext } from '../contexts/SectionContext';
import { RenderSection } from '../components/Section/SectionsRenderer';
import { LdJson } from '../components/LdJson/LdJson';
import { Pages, TemplateTypes } from '../constants/Enums';
import { indexCache } from '../services/Cache';
import { setupHomepageTargeting } from '../services/ads/Targeting';
import { LazyLoadSections } from '../components/Section/LazyLoad/LazyLoadSections';

export const START_LAZY_LOAD_FROM_INDEX = 20;

export async function getServerSideProps(context: any) {
    const cacheKey = `/index`;
    context.res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');

    if (indexCache.get(cacheKey)) {
        const props = indexCache.get(cacheKey);
        return {
            props: {
                content: props.content,
                categories: props.categories,
            },
        };
    }

    const homepageContent = await getHomepageContent(START_LAZY_LOAD_FROM_INDEX + 1);
    const categories = await getNavbarCategories();

    indexCache.set(cacheKey, {
        content: homepageContent,
        categories: categories,
    });

    return {
        props: {
            content: homepageContent,
            categories: categories,
        },
    };
}

type HomeProps = {
    content: HomepageConfigModel;
    categories: CategoryModel[];
};

const Home: FunctionComponent<HomeProps> = ({ content, categories }) => {
    const ssrSections = content.sections.filter((section) => !section.lazyLoad);
    const lazySections = content.sections.filter((section) => section.lazyLoad);
    const [displayLazySection, setDisplayLazySections] = useState<boolean>(false);
    const observer = useRef<any>();

    const lastSectionElementRef = useCallback((node: any) => {
        if (observer.current) {
            observer.current.disconnect();
        }
        observer.current = new IntersectionObserver(
            (entries, observer) => {
                if (entries[0].isIntersecting) {
                    setDisplayLazySections(true);
                }
            },
            {
                rootMargin: '500px',
            },
        );
        if (node) {
            observer.current.observe(node);
        }
    }, []);

    const sectionAdsCounter: any = {
        [TemplateTypes.FOUR_ARTICLES_SINGLE_HORIZONTAL_BANNER]: 0,
        [TemplateTypes.FOUR_ARTICLES_HORIZONTAL_AND_RECT_BANNERS]: 0,
        [TemplateTypes.FIVE_ARTICLES_VERTICAL_BANNER]: 0,
    };

    useEffect(() => {
        setupHomepageTargeting();
    }, []);

    return (
        <NavigationMenuContext.Provider value={categories}>
            <Layout>
                <Head>
                    <title>Woman.bg - за истинската жена</title>
                    <LdJson page={Pages.LANDING} />
                    <script
                        dangerouslySetInnerHTML={{
                            __html: `
                        window['sprtlTargeting'] = {
                            section: ['homepage']
                        };
                    `,
                        }}></script>
                    <script
                        dangerouslySetInnerHTML={{
                            __html: `
                         window.googletag && window.googletag.apiReady ? window.googletag.pubads().setTargeting('section', 'homepage') : '';
                         `,
                        }}></script>
                    <link rel='canonical' href={`${process.env.NEXT_PUBLIC_WEBSITE_URL}`} />
                </Head>
                <main>
                    {ssrSections.length
                        ? ssrSections.map((section: HomepageConfigSectionModel, idx: number) => {
                              if (ssrSections.length === idx + 1) {
                                  return (
                                      <SectionContext.Provider key={section.listId} value={{ pageIndex: idx }}>
                                          <div ref={lastSectionElementRef}>
                                              <RenderSection section={section} sectionAdsCounter={sectionAdsCounter} />
                                          </div>
                                      </SectionContext.Provider>
                                  );
                              } else {
                                  return (
                                      <SectionContext.Provider key={section.listId} value={{ pageIndex: idx }}>
                                          <div>
                                              <RenderSection section={section} sectionAdsCounter={sectionAdsCounter} />
                                          </div>
                                      </SectionContext.Provider>
                                  );
                              }
                          })
                        : null}
                    {displayLazySection && (
                        <LazyLoadSections
                            sections={lazySections}
                            sectionAdsCounter={sectionAdsCounter}
                            startingSection={START_LAZY_LOAD_FROM_INDEX}
                        />
                    )}
                </main>
            </Layout>
        </NavigationMenuContext.Provider>
    );
};

export default Home;
