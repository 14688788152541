import { fetchData } from '../../../http/Http';

export const fetchCategoriesById = async (categoryIds: string[]): Promise<any[]> => {
    const response = await fetchData(`/categories/search?query=*&ids=${categoryIds.join(',')}`, 'GET');
    return response.data;
};

export const fetchCategoryBySlug = async (slug: string) => {
    const response = await fetchData(`/categories/search?query=*&slug=${slug}`, 'GET');
    return response.data;
};

export const fetchAllCategories = async () => {
    const response = await fetchData('/categories?limit=100', 'GET');
    return response.data;
};
