import { fetchData } from '../../../http/Http';
import fetch from 'isomorphic-fetch';

export const fetchListsById = async (listIds: string[]): Promise<any> => {
    const response = await fetchData(`/lists?query=*&status=active&ids=${listIds.join(',')}`, 'GET');
    return response.data;
};

export const fetchListById = async (id: string): Promise<any> => {
    const response = await fetchData(`/lists/${id}`, 'GET');
    return response.data;
};

export const fetchListByIdFromLocal = async (id: string): Promise<any> => {
    const response = await fetch(`/api/lists?id=${id}`, { method: 'GET' });
    return response.json();
};

export const fetchListBySlug = async (slug: string): Promise<any> => {
    const response = await fetchData(`/lists/search?query=*&status=active&slug=${slug}`, 'GET');
    return response.data;
};
