import React, { FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { HomepageConfigSectionModel } from '../../../services/configurator/homepage/models/HomepageConfigModel';
import { ListModel } from '../../../services/content/list/models/ListModel';
import { fetchListByIdFromLocal } from '../../../services/content/list/http/ListHttp';

type Props = {
    section: HomepageConfigSectionModel;
};
export const SectionLazyLoad: FunctionComponent<PropsWithChildren<Props>> = ({ children, section }) => {
    const [content, setContent] = useState<ListModel | null>(null);

    useEffect(() => {
        fetchListByIdFromLocal(section.listId).then((data) => {
            if (data.content.status === 'active') {
                setContent(data.content);
            }
        });
    }, []);

    if (content) {
        return (
            <>
                {React.Children.map(children, (child) =>
                    React.cloneElement(child as ReactElement, {
                        section: content,
                    }),
                )}
            </>
        );
    }

    return null;
};
