import { FunctionComponent, useEffect, useState } from 'react';
import Image from 'next/image';
import { getImageURLByIdFromProxy } from '../../services/content/image/Image';

type Props = {
    id: string | null | undefined;
};
export const ImageAsync: FunctionComponent<Props> = ({ id }) => {
    const [imageUrl, setImageUrl] = useState('/placeholder_main_image.png');

    useEffect(() => {
        if (id) {
            getImageURLByIdFromProxy(id).then((image) => {
                setImageUrl(image);
            });
        }
    }, [id]);

    return <Image className='normalize-nextjs-image' src={imageUrl} alt={''} fill />;
};
