import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { SectionsDivider } from '../SectionsDivider';
import { Routes } from '../../../constants/Routes';
import { ListModel } from '../../../services/content/list/models/ListModel';
import { SectionContext } from '../../../contexts/SectionContext';
import { getDividerTitle } from '../Section.helper';
import { SectionSecondaryContent } from '../SectionSecondaryContent';
import { ArticleModel } from '../../../services/content/article/models/ArticleModel';
import { ImageModel } from '../../../services/content/image/models/ImageModel';
import { ImageAsync } from '../../Image/ImageAsync';
import styles from './SectionProject1Template.module.scss';
import { CategoryModel } from '../../../services/content/category/models/CategoryModel';
import { getCategoryBySlugFromProxy } from '../../../services/content/category/Category';

type Props = {
    section: ListModel | null | undefined;
    lazyLoadImage?: boolean;
};

export const SpecialProjectSectionTemplate: FunctionComponent<Props> = ({ section, lazyLoadImage }) => {
    const [sectionCategory, setSectionCategory] = useState<CategoryModel | null>(null);

    const sectionContext = useContext(SectionContext);
    const pageIndex = sectionContext.pageIndex;
    const displayDivider = pageIndex > 0;

    useEffect(() => {
        getCategoryBySlugFromProxy(section?.configuration?.category?.seo.slug).then((response) => {
            setSectionCategory(response);
        });
    }, []);

    if (section) {
        const dividerTitle = getDividerTitle(section);
        const sectionItems = section.items;
        const categoryImageMainMedia =
            sectionCategory && sectionCategory.mainMedia && sectionCategory.mainMedia[0]
                ? sectionCategory.mainMedia[0]
                : null;

        return (
            <div className={`container mb-0 mb-md-5`}>
                <SectionsDivider
                    title={dividerTitle}
                    display={displayDivider}
                    imageComponent={<SectionDividerImage id={(categoryImageMainMedia as ImageModel)?.id} />}
                    link={sectionCategory ? Routes.openCategory(section?.configuration?.category?.seo?.slug) : '#'}
                />

                <div className={`row px-0 mx-0 justify-content-between`}>
                    {sectionItems.map((content, idx) => {
                        content = content as ArticleModel;
                        return (
                            <SectionSecondaryContent
                                key={`content-key-${content?.id}`}
                                className='col-12 col-md-3 mb-md-3'
                                lazyLoadImage={lazyLoadImage ? lazyLoadImage : false}
                                content={content}
                                category={sectionCategory && sectionCategory.id ? sectionCategory : content.category}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }

    return null;
};

const SectionDividerImage: FunctionComponent<{ id?: string }> = ({ id }) => {
    if (id === undefined) {
        return <></>;
    }

    return (
        <div className={styles.sectionImageContainer}>
            <ImageAsync id={id} />
        </div>
    );
};
