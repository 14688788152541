import { DEFAULT_IMAGE_RATIO, DEFAULT_IMAGE_SIZE, ImageModel, ImageRatios, ImageSizes } from './models/ImageModel';
import { fetchImageById } from './http/ImageHttp';
import { normalizeImage } from './normalizers/ImageNormalizer';
import { getMainMediaImageByCropRatio } from './ImageConfiguration';
import fetch from 'isomorphic-fetch';

export const getImageById = async (imageId: string): Promise<ImageModel | null> => {
    const response = await fetchImageById(imageId);
    return normalizeImage(response);
};

export const getImageURLByIdAndCrop = async (
    imageId: string,
    imageRatio: ImageRatios = DEFAULT_IMAGE_RATIO,
    imageSize: ImageSizes = DEFAULT_IMAGE_SIZE,
): Promise<string> => {
    try {
        const image = await getImageById(imageId);
        return getMainMediaImageByCropRatio([image], imageRatio, imageSize);
    } catch (e: any) {
        return '';
    }
};

export const getImageURLByIdFromProxy = async (imageId: string): Promise<string> => {
    const response: any = await fetch(`${process.env.NEXT_PUBLIC_WEBSITE_URL}/api/images?id=${imageId}`, {
        method: 'GET',
    });
    const responseParsed = await response.json();
    return await responseParsed.content;
};
