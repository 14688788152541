import { FunctionComponent } from 'react';
import { HomepageConfigSectionModel } from '../../services/configurator/homepage/models/HomepageConfigModel';
import { TemplateTypes } from '../../constants/Enums';
import { LeadingNewsSectionTemplate } from './SectionTemplates/LeadingNewsSectionTemplate';
import { FourArticlesHorizontalBannerSectionTemplate } from './SectionTemplates/FourArticlesHorizontalBannerSectionTemplate';
import { FourArticlesHorizontalAndRectBannerSectionTemplate } from './SectionTemplates/FourArticlesHorizontalAndRectBannerSectionTemplate';
import { FiveArticlesVerticalBannerSectionTemplate } from './SectionTemplates/FiveArticlesVerticalBannerSectionTemplate';
import { FiveArticlesNoBannersSectionTemplate } from './SectionTemplates/FiveArticlesNoBannersSectionTemplate';
import { SpecialProjectSectionTemplate } from './SectionTemplates/SpecialProjectSectionTemplate';
import {
    adsFourArticlesSingleHorizontalBanner,
    adsFourArticlesHorizontalAndRectBanner,
    adsFiveArticlesVerticalBanner,
} from '../../services/ads/constants';
import { getAdsForSection } from '../../services/ads/AdService';
import { SectionLazyLoad } from './LazyLoad/SectionLazyLoad';

type Props = {
    section: HomepageConfigSectionModel;
    sectionAdsCounter: any;
};

export const RenderSection: FunctionComponent<Props> = ({ section, sectionAdsCounter }) => {
    if (section) {
        return sectionProjectMap(section, sectionAdsCounter);
    }

    return null;
};

const sectionProjectMap = (section: HomepageConfigSectionModel, sectionAdsCounter: any) => {
    const templateType = section.template as TemplateTypes;

    const fourArticleSingleHorizontalBannerAds = [
        getAdsForSection(adsFourArticlesSingleHorizontalBanner, templateType, sectionAdsCounter),
    ];
    const fourArticlesHorizontalAndRectBannerAds = getAdsForSection(
        adsFourArticlesHorizontalAndRectBanner,
        templateType,
        sectionAdsCounter,
    );
    const fiveArticlesVerticalBannerAds = [
        getAdsForSection(adsFiveArticlesVerticalBanner, templateType, sectionAdsCounter),
    ];

    const map: any = {
        [TemplateTypes.LEADING_NEWS]: <LeadingNewsSectionTemplate lazyLoadImage={false} section={section.content} />,
        [TemplateTypes.FOUR_ARTICLES_SINGLE_HORIZONTAL_BANNER]: (
            <FourArticlesHorizontalBannerSectionTemplate
                section={section.content}
                lazyLoadImage={true}
                ads={fourArticleSingleHorizontalBannerAds}
            />
        ),
        [TemplateTypes.FOUR_ARTICLES_HORIZONTAL_AND_RECT_BANNERS]: (
            <FourArticlesHorizontalAndRectBannerSectionTemplate
                section={section.content}
                lazyLoadImage={true}
                ads={fourArticlesHorizontalAndRectBannerAds}
            />
        ),
        [TemplateTypes.FIVE_ARTICLES_VERTICAL_BANNER]: (
            <FiveArticlesVerticalBannerSectionTemplate
                lazyLoadImage={true}
                section={section.content}
                ads={fiveArticlesVerticalBannerAds}
            />
        ),
        [TemplateTypes.FIVE_ARTICLES_NO_BANNERS]: (
            <FiveArticlesNoBannersSectionTemplate lazyLoadImage={true} section={section.content} />
        ),
        [TemplateTypes.SPECIAL_PROJECT]: (
            <SpecialProjectSectionTemplate lazyLoadImage={true} section={section.content} />
        ),
    };

    const template = map[section.template];
    sectionAdsCounter[templateType] = sectionAdsCounter[templateType] + 1;

    if (template) {
        if (section.lazyLoad) {
            return <SectionLazyLoad section={section}>{template}</SectionLazyLoad>;
        } else {
            return template;
        }
    }

    return <FiveArticlesNoBannersSectionTemplate section={section.content} />;
};
