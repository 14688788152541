import React, { FunctionComponent } from 'react';
import styles from '../../styles/SectionSecondaryContentVertical.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import { ArticleModel } from '../../services/content/article/models/ArticleModel';
import { VideoModel } from '../../services/content/video/models/VideoModel';
import { CategoryModel } from '../../services/content/category/models/CategoryModel';
import { getMainMediaImageByCropRatio } from '../../services/content/image/ImageConfiguration';
import { ImageRatios } from '../../services/content/image/models/ImageModel';
import { Routes } from '../../constants/Routes';
import { PlayButtonImage } from '../Partials/PlayButton/PlayButton';
import { hasAuthors } from '../../constants/GlobalHelpers';
import { ArticlePartialModel } from '../../services/content/article/models/ArticlePartialModel';

type ContentProps = {
    content: ArticleModel | VideoModel | ArticlePartialModel | null;
    category: CategoryModel | null | undefined;
    className?: string;
    isVideo?: boolean;
    lazyLoadImage?: boolean;
};

export const SectionSecondaryContentVertical: FunctionComponent<ContentProps> = ({
    content,
    className,
    lazyLoadImage,
}) => {
    if (content) {
        content = content as ArticleModel;
        const category = content?.category;
        const image: string = getMainMediaImageByCropRatio(content.mainMedia, ImageRatios.RATIO_4X3);
        return (
            <div className={`${className ? className : ''} pt-3 pb-3 pt-md-0 pb-md-0`}>
                <div className={`d-flex flex-column flex-md-row flex-lg-row align-items-start`}>
                    <Link href={Routes.openArticle(content.id)} className='w-100 mb-2 position-relative me-3'>
                        <PlayButtonImage content={content} className={`${styles.pButton}`} />
                        <Image
                            role='button'
                            className={`${styles.leadingImage} mb-1 mb-md-1`}
                            src={image ? image : '/placeholder_main_image.png'}
                            alt={content.title}
                            priority={lazyLoadImage ? !lazyLoadImage : true}
                            fill
                        />
                    </Link>
                    <div className={`w-100 d-flex flex-column align-items-md-start align-items-center mb-3 mb-md-3`}>
                        <div className='d-flex flex-row justify-content-between w-100 mb-3 mb-md-0 mb-lg-0'>
                            <Link
                                className={`${styles.categoryText} font-lato-400 mb-md-2 mb-lg-2 d-flex flex-column justify-content-end`}
                                href={Routes.openCategory(category && category.seo ? category.seo.slug : '#')}>
                                {category?.title.toUpperCase()}
                            </Link>
                            {hasAuthors(content.authors) ? (
                                <div className={`${styles.author} font-lato-400 d-inline-block d-md-none d-lg-none`}>
                                    {`Автор ${content.authors[0].name}`}
                                </div>
                            ) : null}
                        </div>
                        <Link
                            className={`${styles.contentTitle} font-playfair-400 w-100 text-start text-decoration-none`}
                            href={Routes.openArticle(content.id)}>
                            {content.title}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    return <></>;
};
