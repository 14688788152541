import { ArticleModel } from '../content/article/models/ArticleModel';
import { TagModel } from '../content/tag/models/TagModel';
import { CategoryModel } from '../content/category/models/CategoryModel';
import { safeSetAdsTargeting } from './AdService';
import { AuthorModel } from '../content/author/models/AuthorModel';

export const setupArticleTargeting = (article: ArticleModel, tags: TagModel[]) => {
    safeSetAdsTargeting('section', `news_view`);
    safeSetAdsTargeting('article', `${article.id}`);
    safeSetAdsTargeting('category', `${article?.category?.id}`);
    safeSetAdsTargeting(
        'tag',
        tags.map((tag) => tag.id),
    );
};

export const setupAuthorsPageTargeting = (author: AuthorModel | null) => {
        safeSetAdsTargeting('section', `author`);
    safeSetAdsTargeting('author', `${author ? author.id : ''}`);
};

export const setupTagsPageTargeting = (tag: TagModel | null) => {
    safeSetAdsTargeting('section', `tag`);
    safeSetAdsTargeting('tag', `${tag ? tag.id : ''}`);
};

export const setupSearchTargeting = () => {
    safeSetAdsTargeting('section', `search`);
};

export const setupCategoriesPageTargeting = (category: CategoryModel) => {
    safeSetAdsTargeting('section', `category`);
    safeSetAdsTargeting('category', `${category.id}`);
};

export const setupHomepageTargeting = () => {
    safeSetAdsTargeting('section', `homepage`);
};
